:root {
  --mainBlue: #2a2a72;
  --lightBlue: #009ffd;
  --mainWhite: #f3f3f3;
  --mainDark: #232528;
  --mainYellow: #ff8400;
  --mainlightblue: #6d84f6;
  --mainDarkBlue: #001822;
  --mainGreen: #06b672;
}

body {
  visibility: visible !important;
  font-family: "Oswald", sans-serif !important;
  background: var(--mainWhite) !important;
  color: var(--mainDark) !important;
}

/*start of chat bot*/
.App {
  font-family: sans-serif;
  text-align: center;
}
.options {
  padding-bottom: 15px;
}

.options-header {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.option-item {
  padding: 8px 15px;
  border: 1px solid #2898ec;
  border-radius: 25px;
  color: #1f91e7;
  font-size: 0.9rem;
  margin: 3px;
  box-shadow: inset 0px 0px 0px #2273c4;
  transition: all 0.5s;
  cursor: pointer;
}

.option-item:hover {
  box-shadow: inset 195px 0px 0px #2273c4;
  color: #ffffff;
}

.stats {
  color: white;
  font-size: 0.9rem;
  background-color: rgba(45, 159, 253, 0.589);
  display: flex;
  background: linear-gradient(to top right, rgb(15, 146, 221), #055991);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}
.column-left {
  text-align: right;
}
.column-right {
  text-align: left;
  padding: 8px;
}

.url-link {
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 3px;
  background-color: #2273c4;
  display: flex;
  align-items: center;
  color: #fff;
}
.url-icon {
  width: 35px;
  margin-right: 10px;
}
.url-header {
  font-size: 0.9rem;
  font-weight: lighter;
}

.tel-link {
  font-size: 0.7rem;
  padding: 2px 5px;
  border-radius: 30px;
  background-color: #2273c4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  fill: #fff;
}

.app-chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  background-color: #0d2d44;
  border: none;
  position: fixed;
  bottom: 25px;
  z-index: 9999;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.6s ease-in-out;
}
.app-chatbot-button:hover {
  transform: scale(1.1);
}
.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 40px;
  bottom: 55px;
  z-index: 9999;
  box-shadow: 15px 5px 13px rgba(91, 81, 81, 0.4);
  border-radius: 5px;
}
.app-chatbot-button-icon {
  fill: #fff;
}
/*end of chat bot*/

/*whatsapp*/

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}

/*whatsapp*/

balloon {
  font-size: 15px;
  border: none;
  display: block;
}

.text-title {
  font-family: "Lobster", cursive;
  padding: 20px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.text-blue {
  color: var(--mainBlue);
}
/*Quick view card view design */
.card {
  /* Add shadows to create the "card" effect */

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.card-service {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

.text-bright {
  color: var(--lightBlue);
}

.btn-black {
  background: transparent;
  text-transform: capitalize;
  font-size: 0.8rem !important;
  color: var(--mainDark);
  border-radius: 0rem !important;
  border: 0.1rem solid var(--mainDark) !important;
}

.btn-black:hover {
  background: var(--mainDark) !important;
  color: var(--mainWhite) !important;
}
.table-header {
  border-top: 2px solid grey;
  border-bottom: 2px solid grey;
}
.table-row-details {
  border-bottom: 2px solid grey;
}
.grid-container {
  background-color: rgb(253, 253, 253);
  border-radius: 5px;
}
.cart-icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--mainYellow);
}

.books-count {
  background-color: springgreen;
}

.flip-box {
  background-color: transparent;
  width: 100%;
  height: 200px;
  border: 2px solid #f1f1f1;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}

.searchButton {
  color: #2a2a72;
  border: none;
  height: 40px;
  width: 20%;
}

.searchButton:hover {
  background-color: #fa912e;
}
.searchText {
  font-size: 12px;
}

.th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}
.avatar-icon {
  display: flex;
  justify-content: center;
}

.signupimg {
  text-align: center;
  width: 150px;
}
.header-text-sm {
  text-align: center;
  color: var(--mainlightblue);
  line-height: 6rem;
  font-size: 35px;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.header-text {
  text-align: center;
  color: var(--mainlightblue);
  line-height: 6rem;
  font-size: 50px;
  font-weight: bolder;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.row {
  display: flex;
  margin-top: 20px;
  margin-bottom: 5px;
}

.row > div {
  flex: 1;
}
.signup-container {
  width: 500px;
  height: 650px;
  margin-top: 50px;
  border-radius: 12px;
  border: 2px solid var(--mainlightblue);
}
.login-container {
  width: 500px;
  height: 550px;
  margin-top: 50px;
  border-radius: 12px;
  border: 2px solid var(--mainlightblue);
}
.popup-container {
  height: 100vh;
  background: url(./images/bg.gif) no-repeat center center fixed;
  background-size: cover;
  display: flex;
  border-radius: 0px;
  -moz-box-shadow: inset 0 0 10px var(--mainMustard);
  -webkit-box-shadow: inset 0 0 10px var(--mainMustard);
  box-shadow: inset 0 0 10px var(--mainMustard);
  border: 1px solid var(--mainMustard);
  z-index: 22;
  position: relative;
  justify-content: center;
}

.otp-field {
  width: 38px;
  margin-right: 10px;
  padding-left: 12px;
  height: 40px;
}

.popup-container::after {
  background: rgba(0, 0, 0, 0.7);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2222;
}

.container-mg {
  background-color: var(--mainDarkBlue);

  display: flex;
  justify-content: center;

  height: 100vh;
}

.button-is-success {
  background-color: #06b672;
  text-transform: capitalize;
  font-size: 18px !important;
  color: var(--mainWhite);
  margin-right: 5px;
  border-radius: 0.8rem !important;
  border: 0.1rem solid #06b672 !important;
}

.button-is-success:hover {
  background-color: #99012f;
  color: azure;
  border: 0rem !important;
  border: 0.1rem solid #99012f !important;
}

.AK-sidebar-text {
  font-size: 22px;
  font-weight: bold;
  margin-top: 25px;
  margin-left: 0rem;
}

.input-phone {
  width: 70% !important;
  border-radius: 8px;
  height: 50px;
  margin: 12px;
}

.input-phonecode {
  width: 20% !important;
  border-radius: 8px;
  height: 50px;
  margin: 12px;
}

.input {
  width: 90%;
  border-radius: 8px;
  height: 50px;
  margin: 12px;
}

.input-phone-s {
  width: 70% !important;
  border-radius: 8px;
  height: 40px;
  margin: 2px;
}

.input-phonecode-s {
  width: 20% !important;
  border: none;
  border-radius: 8px;
  height: 40px;
  margin: 2px;
  white-space: nowrap;
}

.input-signup {
  width: 90%;
  border-radius: 8px;
  height: 40px;
  margin: 2px;
}

.work-prog {
  position: relative;
  text-align: center;
  color: rgb(55, 9, 139);
  margin-top: 30px !important;
  background-color: #ffffff !important;
}

.table-accession-title {
  width: 10%;
  background-color: #99e1fd;
}

.table-book-title {
  width: 30%;
  background-color: #99e1fd;
}
.table-author-title {
  width: 20%;
  background-color: #99e1fd;
}

.table-head-cls {
  width: 8%;
  background-color: #99e1fd;
}
.table-head-scope {
  width: 8%;
  background-color: #99e1fd;
}
.table-head-stat {
  width: 12%;
  background-color: #99e1fd;
}

.book-accession-title {
  width: 10%;
}
.book-title {
  width: 30%;
}
.author-title-sps {
  width: 20%;
}
.book-cls-sps {
  width: 8%;
}

.book-scope-sps {
  width: 8%;
}
.book-status-sps {
  width: 12%;
}

.search-input {
  width: 80%;
  height: 40px;
}
.searchoption {
  border: 0.1rem solid var(--mainDark) !important;
  width: 50%;
}
.search-input-ed {
  width: 80%;

  height: 40px;
}
.button-approve {
  background-color: #7108b8;
  border: #ffffff;
  border-radius: 0.3rem;
  color: aliceblue;
}
.button-approve:hover {
  background-color: #048821 !important;
}
.button-del {
  background-color: #7108b8;
  border: #ffffff;
  border-radius: 0.3rem;
  color: aliceblue;
}
.button-del:hover {
  background-color: #e90606 !important;
}

.nav-link-title {
  font-size: 18px;
  color: rgb(255, 255, 255);
}
.navigation__navlinks {
  background-color: white;
  color: rgb(55, 9, 139) !important;
}

.navigation__navlinks:hover {
  background-color: rgb(55, 9, 139);
  color: white !important;
}
.brand_name {
  color: #2a2a72;
  font-weight: bold;
}
.services-bn {
  margin-right: 120px;
  margin-left: 120px;
}
.services-main {
  display: table;
  height: 400px;
  line-height: normal;
  width: 100%;
}
.services-info {
  display: table-cell;
  vertical-align: middle;
  min-width: "350px";
}
.btn:hover {
  color: white;
  background-color: #1362b3 !important;
}

/*---flyier testing css----*/

.flier {
  pointer-events: none;
}

.flier > * {
  /* Adjust animation duration to change the element’s speed */
  animation: fly 50s linear infinite;
  pointer-events: none !important;
  top: 0;
  left: 0;
  transform: translateX(-120%) translateY(-120%) rotateZ(0);
  position: fixed;
  animation-delay: 1s;
  z-index: 999999;
}

/* Keyframe values control where the element will begin
    and end its trajectory across the screen. Each rule
    represents a path the element follows across the screen. */

@keyframes fly {
  98.001%,
  0% {
    display: block;
    transform: translateX(-200%) translateY(100vh) rotateZ(0deg);
  }

  15% {
    transform: translateX(100vw) translateY(-100%) rotateZ(180deg);
  }

  15.001%,
  18% {
    transform: translateX(100vw) translateY(-30%) rotateZ(0deg);
  }

  40% {
    transform: translateX(-200%) translateY(3vh) rotateZ(-180deg);
  }

  40.001%,
  43% {
    transform: translateX(-200%) translateY(-100%) rotateZ(-180deg);
  }

  65% {
    transform: translateX(100vw) translateY(50vh) rotateZ(0deg);
  }

  65.001%,
  68% {
    transform: translateX(20vw) translateY(-200%) rotateZ(180deg);
  }

  95% {
    transform: translateX(10vw) translateY(100vh) rotateZ(0deg);
  }
}

/*---------------------------------------
       PRE LOADER              
  -----------------------------------------*/

.preloaderidntica {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: none repeat scroll 0;
}

.spinner {
  color: #150464;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
.preloaderidntica span {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  font-size: 50px;
  letter-spacing: 12px;
  color: #fff;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-----------extra spinner css --------------*/

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

/*---------------------------------*/

@media only screen and (min-width: 580px) {
  .navbar-mobile {
    display: none;
  }
}

/* start templatemo 600 media quires */
@media only screen and (max-width: 860px) {
  .navbar-brand-ak {
    display: none;
  }
  .nav-link-title {
    font-size: 12px !important;
    color: rgb(252, 252, 252);
  }
  .top-navbar {
    position: absolute;
    top: 10px !important;
    right: 10px;
  }
}
@media only screen and (max-width: 680px) {
  .navbar-brand-ak {
    display: none;
  }
  .nav-link-title {
    display: none;
  }

  .nav-link-title-small {
    font-size: 12px !important;
    color: rgb(252, 252, 252);
    position: absolute !important;
    left: 3px !important;
    line-height: 0.8 !important;
  }
  .top-navbar {
    position: absolute;
    top: 10px !important;
    right: 10px;
  }

  .sm-container {
    display: none;
  }
}

@media only screen and (max-width: 580px) {
  .navbar-bg {
    display: none;
  }

  .nav-link-title-small {
    font-size: 12px !important;
    color: rgb(252, 252, 252);
    position: absolute !important;
    left: 15px !important;
    top: 15px !important;
  }
  .top-navbar {
    position: absolute;
    top: 10px !important;
    right: 10px;
  }
  li {
    font-size: 10px !important;
  }

  .search-input {
    width: 70%;
    height: 30px;
  }
  .search-input-ed {
    width: 70%;

    height: 30px;
  }

  .searchButton {
    height: 30px;
    width: 30%;
  }

  .book-title-sps {
    width: 50% !important;
    font-size: 10px;
  }
  .author-title-sps {
    width: 15% !important;
    font-size: 10px;
  }
  .book-cls-sps {
    width: 5% !important;
    font-size: 10px;
  }
  .book-status-sps {
    width: 15% !important;
    font-size: 10px;
  }
  .book-scope-sps {
    width: 15%;
    font-size: 10px;
  }

  .table-head-btitle {
    width: 50% !important;
    font-size: 10px;
  }

  .table-head-atitle {
    width: 15% !important;
    font-size: 10px;
  }

  .table-head-cls {
    width: 5% !important;
    font-size: 10px;
  }

  .table-head-stat {
    width: 15% !important;
    font-size: 10px;
  }

  .table-head-scope {
    width: 15% !important;
    font-size: 10px;
  }

  .bg-container {
    display: none;
  }
  .sm-container {
    display: inherit !important;
    font-size: 10px;
    width: 100% !important;
  }
  .brand_name {
    font-size: 14px !important;
  }
  .services-bn {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 560px) {
  .card h5 {
    font-size: 14px;
  }
  .card h1 {
    font-size: 35px;
  }
  .dash-img {
    width: "300px" !important;
  }
  .text-title {
    font-size: 22px;
  }
  .errspan {
    float: right;
    margin-right: 6px;
    margin-top: -20px;
    position: relative;
    z-index: 2;
    color: red;
  }
}
@media only screen and (max-width: 420px) {
  .services-info {
    display: block;
    width: 280px;
    margin: auto;
  }
  .section-title h2 {
    font-size: 18px;
    text-align: center;
  }
  .services-main {
    height: 280px;
  }
  .card h5 {
    font-size: 14px;
  }
  .card h1 {
    font-size: 35px;
  }
  .dash-img {
    width: "300px" !important;
  }
}

@media only screen and (max-width: 300px) {
  .services-info {
    display: block;
    width: 170px;
    margin: auto;
  }
  .section-title h2 {
    font-size: 18px;
  }
  .dash-img {
    width: "300px" !important;
  }
  .card h5 {
    font-size: 14px;
  }
  .card h1 {
    font-size: 35px;
  }
}
