.bottom{

   padding-top: 230px;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .bottom-nav {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    border-top: 1px solid rgb(230, 230, 230);
  }
  .bn-tab {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }