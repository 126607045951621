/*------------------------------------------------------------------
    Version: 1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
    [Table of contents]

    1. IMPORT FONTS
    2. IMPORT FILES
    3. SKELETON
    4. WP CORE
    5. HEADER
    6. SECTIONS
    7. SECTIONS
    8. PORTFOLIO
    9. TESTIMONIALS
    10. PRICING TABLES
    11. ICON BOXES
    12. MESSAGE BOXES
    13. FEATURES
    14. CONTACT
    15. FOOTER
    16. MISC
    17. BUTTONS
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
    IMPORT FONTS
-------------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

/*------------------------------------------------------------------
    IMPORT FILES


@import url(flaticon.css);
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
    HEADER
-------------------------------------------------------------------*/

/*----------------------------------------*/

.icon_wrap {
  background-color: #001822;
  width: 100px;
  height: 100px;
  display: block;
  line-height: 100px;
  font-size: 65px;
  color: #fcfcfc;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  padding: 0 !important;
  border: 0 !important;
}

.stat-wrap h3 {
  font-size: 28px;
  font-weight: 500;
  color: rgb(2, 68, 22);
  margin: 0;
  padding: 0;
  line-height: 2;
}

.stat-wrap p {
  font-size: 38px;
  color: #001822;
  margin: 0;
  font-weight: 300;
  padding: 4px 0 0;
  line-height: 2 !important;
}

/*-----------Digit Roll----------------*/

.home-con {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8; /* for demo purpose  */
}
.overlay-top-con {
  z-index: 9;
  margin: 20px; /* for demo purpose  */
}
.con-test {
  border-right: 2px Solid black;
  background-color: #001822;
  height: 1000px;

  box-shadow: 10px 0 5px -2px #888;
}

.dash-head {
  background-color: #2a2a72;
}
.avatar-icon {
  padding: 5px;
  margin: 2px;
}
.dash-board {
  background-color: #dbd8d8;
}
.list-group-item {
  width: 100%;
}
.Settings .order-item-table .table td {
  padding: 0px;
}
.order-item-table {
  width: 100% !important;
  padding-top: 15px;
}
.order-item-table .table td {
  padding: 0px;
  border: none;
}
.order-item-table table {
  margin: 0;
  padding: 0;
  border: none;
}
.order-item-table p {
  font-weight: 400;
  margin: 0;
  color: var(--mainMustard);
}
/**********------------------PopUp ------------------************/

.popup-container {
  top: 50%;
  width: 90%;
  /*height: 100%;
 width: 350px;  */

  height: 440px;
  background-size: cover;
  display: inline-block;
  border-radius: 10px;
  -moz-box-shadow: inset 0 0 10px var(--mainMustard);
  -webkit-box-shadow: inset 0 0 10px var(--mainMustard);
  box-shadow: inset 0 0 10px var(--mainMustard);
  border: 1px solid var(--mainMustard);
  z-index: 22;
  position: relative;
}

.popup-container::after {
  background: rgba(5, 5, 5, 0.5);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2222;
}

.popup-container h3 {
  font-size: 16px;
}
.popup-container .img-success-popup {
  padding-top: 50px;
  width: 60px !important;
}

/*-----From old styles ---*/



/* start contact */
#contact
    {	 background-color: #fdfdfd;
        background-size: cover;
        background-repeat: no-repeat;
       
        color:#FFF;
    }
#contact .social-icon{
	color:#FFF;
	text-align:center;
}
#contact h3
    {
        font-weight: bold;
        padding-bottom: 20px;
    }
#contact .fa
    {
        color: #33ccff;
        font-size: 18px;
        padding-bottom: 14px;
		margin-bottom: 20px;
		
    }
#contact input
    {
		color:#000;
		 width: 100%;
        
		 background: #f2f2f2;
        border-radius: 4px;
        box-shadow: none;
		
        margin-bottom: 20px;
        transition: all 0.4s ease-in-out;
    }
#contact textarea{
	color:#000;
	 background: #f2f2f2;
        border-radius: 4px;
        box-shadow: none;
		 width: 100%;
		
        margin-bottom: 20px;
        transition: all 0.4s ease-in-out;
}
#contact button{
	
	 	color: #202020;
        font-weight: bold;
        margin-bottom: 80px;
		width:100%;
		height:100%;
		
		
		
}
#contact .copyright{
	text-align:center;
	
}

#contact button:hover
    {
		background-color:#F96;
    }


/* end contact */


/*start of Chat bot */

.App {
  font-family: sans-serif;
  text-align: center;
}
.options {
  padding-bottom: 15px;
}

.options-header {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.option-item {
  padding: 8px 15px;
  border: 1px solid #2898ec;
  border-radius: 25px;
  color: #1f91e7;
  font-size: 0.9rem;
  margin: 3px;
  box-shadow: inset 0px 0px 0px #2273c4;
  transition: all 0.5s;
  cursor: pointer;
}

.option-item:hover {
  box-shadow: inset 195px 0px 0px #2273c4;
  color: #ffffff;
}

.stats {
  color: white;
  font-size: 0.9rem;
  background-color: rgba(45, 159, 253, 0.589);
  display: flex;
  background: linear-gradient(to top right, rgb(15, 146, 221), #055991);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}
.column-left {
  text-align: right;
}
.column-right {
  text-align: left;
  padding: 8px;
}

.url-link {
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 3px;
  background-color: #2273c4;
  display: flex;
  align-items: center;
  color: #fff;
}
.url-icon {
  width: 35px;
  margin-right: 10px;
}
.url-header {
  font-size: 0.9rem;
  font-weight: lighter;
}

.tel-link {
  font-size: 0.7rem;
  padding: 2px 5px;
  border-radius: 30px;
  background-color: #2273c4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  fill: #fff;
}

.app-chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  background-color: #0d2d44;
  border: none;
  position: fixed;
  bottom: 25px;
  z-index: 9999;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.6s ease-in-out;
}
.app-chatbot-button:hover {
  transform: scale(1.1);
}
.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 40px;
  bottom: 55px;
  z-index: 9999;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  border-radius: 5px;
}
.app-chatbot-button-icon {
  fill: #fff;
}


/*end of chatbot */





/* start social icon */
.social-icon
    {
        position: relative;
        padding: 0px;
        margin: 0px;
		text-align:left;
        
    }
.social-icon li
    {
        display: inline-block;
        list-style: none;
    }
.social-icon li a
    {
        color: #333;
        font-size: 13px;
        text-decoration: none;
        transition: all 0.4s ease-in-out;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
    }
.social-icon li a:hover
    {
        background: #f2f2f2;
    }
/* end social icon */

@media only screen and (max-width: 1199px) {
    .flexslider h1 { font-size: 30px; }
    .flexslider h2 { font-size: 24px; }
}

/* start templatemo 768 media quires */
@media only screen and ( max-width: 768px ) {
    .navbar-default .navbar-brand { margin-left: 20px; }
    .navbar-default .navbar-nav { margin: 0; }
    .navbar-default .navbar-collapse
    {
        padding-left: 20px;
        padding-right: 20px;
    }
    .flexslider h1 { font-size: 26px; }
    .flexslider h2 { font-size: 18px; }
    .templatemo-slider-btn { 
        margin-top: 10px; 
        width: 180px;
        height: 40px;
        padding-top: 8px;
    }
}
/* end templatemo 768 media quires */

/* start templatemo 600 media quires */
@media only screen and ( max-width: 640px ) {   
    .flexslider h1 { font-size: 20px; }
    .flexslider h2 { font-size: 14px; }
    .templatemo-slider-btn {
        width: auto;
        height: auto;
        padding: 10px 20px;
        margin-top: 10px; 
    }

}
/* end templatemo 600 media quires */
